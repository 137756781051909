<template>
    <div>
        <v-data-table no-data-text="ไม่มีข้อมูล" :headers="headers" :items="items" class="elevation-1">
            <template v-slot:[`item.fullName`]="{ item }">
                {{ item.firstName }} {{ item.lastName }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <div class="d-flex">
                    <v-btn icon color="green" @click="onClickAction('active', item)"><v-icon>mdi-check</v-icon></v-btn>
                    <v-btn icon color="red" @click="onClickAction('deactive', item)"><v-icon>mdi-close</v-icon></v-btn>
                    <v-btn icon color="yellow" @click="onClickAction('pending', item)"><v-icon>mdi-backup-restore</v-icon></v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        items: Array
    },
    data() {
        return {
            headers: [
                {
                    text: "ชื่อ-นามสกุล",
                    value: "fullName",
                    class: "primary--text",
                },
                {
                    text: "สังกัด",
                    value: "affiliation",
                    class: "primary--text",
                },
                {
                    text: "ตำแหน่ง",
                    value: "position",
                    class: "primary--text",
                },
                {
                    text: "อีเมล",
                    value: "email",
                    class: "primary--text",
                },
                {
                    text: "เบอร์โทร",
                    value: "tel",
                    class: "primary--text",
                },
                {
                    text: "เบอร์โทรของหน่วยงาน",
                    value: "telOfAffiliation",
                    class: "primary--text",
                },
                {
                    text: "อีเมลของหน่วยงาน",
                    value: "emailOfAffiliation",
                    class: "primary--text",
                },
                {
                    text: "ดำเนินการ",
                    value: "action",
                    class: "primary--text",
                },
            ]
        }
    },
    methods: {
        onClickAction(action, item) {
            this.$emit('onAction', action, item);
        }
    }
}
</script>