<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="false"
      :mini-variant.sync="mini"
      permanent
      app
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-avatar class="blue" size="40">
            <span class="white--text text-h5">{{ userName[0] || "U" }}</span>
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-title>{{ userName || "General User" }}</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{item.title}}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app class="white">
      <div class="d-flex">
        <BaseLogo />
      </div>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="checkUserActive"
            small
            @click="logout"
            color="error"
            class="pa-4"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="white">mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>ออกจากระบบ</span>
      </v-tooltip>
      <v-btn
        small
        @click="doSearch"
        color="primary"
        class="pa-4"
      >
        ค้นหานวัตกรรม
      </v-btn>
    </v-app-bar>
    <div class="ma-8">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import api from "@/apis";
import BaseLogo from "@/components/BaseLogo";
export default {
  components: {
    BaseLogo,
  },
  data() {
    return {
      drawer: false,
      mini: true,
      menus: [
        { title: "หน้าหลัก", icon: "mdi-home", to: { name: "main" } },
        {
          title: "นวัตกรรมของฉัน",
          icon: "mdi-briefcase",
          to: { name: "mine" },
        },
        {
          title: "ค้นหานวัตกรรม",
          icon: "mdi-magnify",
          to: { name: "searchInnovation" },
        },
        {
          title: "เกี่ยวกับเรา",
          icon: "mdi-information",
          to: { name: "aboutus" },
        },
        {
          title: 'E-BOOK',
          icon: "mdi-book-open-page-variant",
          to: { name: "ebook" }
        },
        {
          title: "เข้าสู่ระบบ",
          icon: "mdi-login",
          to: { name: "login" },
        },
        {
          title: "จัดการบัญชี",
          icon: "mdi-account-card",
          to: { name: "manageAccounts" },
        },
      ]
    };
  },
  computed: {
    userName() {
      return this.userData
        ? this.userData.firstName + " " + this.userData.lastName
        : "";
    },
    checkUserActive() {
      if (
        (!this.userData && localStorage.getItem("rdtk") == "null") ||
        !localStorage.getItem("rdtk")
      ) {
        return false;
      }

      return true;
    },
    items() {
      if (!this.checkUserActive) {
        return this.menus.slice(0, 6);
      }

      return this.userData?.role != 'admin' ? this.menus.slice(0, 5) : [...this.menus.slice(0, 5), this.menus[6]];
    }
  },
  methods: {
    logout() {
      api.doLogout();
    },
    doLogin() {
      this.$router.push({ name: "login" });
    },
    doSearch () {
      this.$router.push({ name: 'searchInnovation' })
    }
  },
};
</script>