import authenticationApi from '@/apis/authentication'
export default async function auth({ next, store }) {
    if (localStorage.getItem('rdtk') == 'null' || !localStorage.getItem('rdtk')) {
        return next({ name: 'login' })
    }

    store.commit("setLoading");
    let IsValid = await authenticationApi.getProfile();
    store.commit("setLoading");
    if (!IsValid) {
        return next({ name: 'login' })
    } else {
        return next();
    }
}