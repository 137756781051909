import api from './index';
export default {
    async getMyInnovations() {
        const { res, err } = await api.axios.get('/innovation/data/mine');
        return err ? [] : res.data.data;
    },
    async createInnovation(model) {
        const { res, err } = await api.axios.post('/innovation', model);
        return err ? false : res.data.data;
    },
    async updateInnovation(id, model) {
        const { res, err } = await api.axios.put('/innovation/' + id, model);
        return err ? false : res.data.data;
    },
    async getInnovation(id) {
        const { res, err } = await api.axios.get('/innovation/' + id);
        return err ? null : res.data.data;
    },
    async searchInnovations(model) {
        const { res, err } = await api.axios.get('/innovation/data/search', { params: model });
        return err ? [] : res.data.data;
    },
    async getInnovationDashboard() {
        const { res, err } = await api.axios.get('/innovation/data/dashboard');
        return err ? [] : res.data.data;
    },
    async deleteInnovation(id) {
        const { res, err } = await api.axios.delete('/innovation/' + id);
        return err ? false : res.data.data;
    },
    async getInnovations(status) {
        const { res, err } = await api.axios.get('/innovation/data/download?status=' + status);
        return err ? [] : res.data.data;
    }
}