<template>
    <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition" style="height: 100vh; width: 100vw;">
        <v-card style="background: #666666;">
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="$emit('close')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <flipbook class="flipbook pa-5" :pages="pages" v-slot="flipbook" ref="flipbook" :startPage="pageNum">
                <div class="action-bar">
                    <v-btn fab small color="white" :class="{ disabled: !flipbook.canFlipLeft }"
                        @click="flipbook.flipLeft"><v-icon dark>mdi-arrow-left-circle</v-icon></v-btn>
                    <v-btn fab small color="white" :class="{ disabled: !flipbook.canZoomOut }"
                        @click="flipbook.zoomOut"><v-icon dark>mdi-minus-circle</v-icon></v-btn>
                    <span class="white--text pa-2">
                        แผ่น {{ flipbook.page }} จาก {{ flipbook.numPages }}
                    </span>
                    <v-btn fab small color="white" :class="{ disabled: !flipbook.canZoomIn }"
                        @click="flipbook.zoomIn"><v-icon dark>mdi-plus-circle</v-icon></v-btn>
                    <v-btn fab small color="white" :class="{ disabled: !flipbook.canFlipRight }"
                        @click="flipbook.flipRight"><v-icon dark>mdi-arrow-right-circle</v-icon></v-btn>
                </div>
            </flipbook>
        </v-card>
    </v-dialog>
</template>

<script>
import Flipbook from 'flipbook-vue/vue2'
export default {
    components: {
        Flipbook
    },
    props: {
        value: Boolean,
        title: String,
        pages: Array
    },
    data() {
        return {
            pageNum: null
        }
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    mounted() {
        window.addEventListener('keydown', (ev) => {
            const flipbook = this.$refs.flipbook
            if (!flipbook) return
            if (ev.keyCode == 37 && flipbook.canFlipLeft) flipbook.flipLeft()
            if (ev.keyCode == 39 && flipbook.canFlipRight) flipbook.flipRight()
        })

        window.addEventListener('hashchange', this.setPageFromHash)
        this.setPageFromHash()

        this.$refs.flipbook.viewWidth = 750;
        this.$refs.flipbook.viewHeight = 600;
    },
    methods: {
        setPageFromHash() {
      const n = parseInt(window.location.hash.slice(1), 10)
      if (isFinite(n)) this.pageNum = n
    },
    }
}
</script>

<style scoped>
.flipbook {
    width: 90vw;
    height: 90vh;
}

.action-bar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>