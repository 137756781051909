<template>
  <single-layout>
    <div style="max-width: max-content; margin: 0 auto">
      <v-card
        elevation="6"
        shaped
        width="500px"
        height="max-content"
        class="mb-6 pa-4"
      >
        <BaseTopic name="ลงทะเบียน" class="mb-3" />
        <ValidationObserver ref="observer">
          <div>
            ชื่อ
            <ValidationProvider
              rules="required|max:250"
              name="ชื่อ"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.firstName"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            นามสกุล
            <ValidationProvider
              rules="required|max:250"
              name="นามสกุล"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.lastName"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            เบอร์โทรติดต่อ
            <ValidationProvider
              rules="required|max:50"
              name="เบอร์โทรติดต่อ"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.tel"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            อีเมลของผู้สมัคร
            <ValidationProvider
              rules="required|email|max:100"
              name="อีเอีเมลของผู้สมัคร"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.email"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            สังกัดหน่วยงาน
            <ValidationProvider
              rules="required"
              name="สังกัดหน่วยงาน"
              v-slot="{ errors }"
            >
                 <v-combobox
          v-model="item.affiliation"
          :items="affiliationList"
                dense
                outlined
                solo
                :error-messages="errors"
        ></v-combobox>
            </ValidationProvider>
          </div>
          <div>
            ตำแหน่งในสังกัดหน่วยงาน
            <ValidationProvider
              rules="required|max:100"
              name="ตำแหน่งในสังกัดหน่วยงาน"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.position"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            อีเมลของสังกัดหน่วยงาน
            <ValidationProvider
              rules="required|email|max:100"
              name="อีเมลของสังกัดหน่วยงาน"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.emailOfAffiliation"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            เบอร์โทรติดต่อของสังกัดหน่วยงาน
            <ValidationProvider
              rules="required|max:50"
              name="เบอร์โทรติดต่อของสังกัดหน่วยงาน"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.telOfAffiliation"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            ชื่อผู้ใช้งานสำหรับใช้เข้าสู่ระบบ
            <ValidationProvider
              rules="required|min:6|max:250"
              name="ชื่อผู้ใช้งาน"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.username"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            รหัสผ่าน
            <ValidationProvider
              rules="required|min:8|max:250"
              name="รหัสผ่าน"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.password"
                solo
                dense
                outlined
                single-line
                type="password"
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            ยืนยันรหัสผ่าน
            <ValidationProvider
              rules="required|confirmed:รหัสผ่าน"
              name="ยืนยันรหัสผ่าน"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.confirmPassword"
                solo
                dense
                outlined
                single-line
                type="password"
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </ValidationObserver>
        <div>
          <v-btn block color="success" class="mt-3" @click="doRegister"
            >ลงทะเบียน</v-btn
          >
          <v-btn block color="error" class="mt-2" @click="doCancel"
            >ยกเลิก</v-btn
          >
        </div>
      </v-card>
    </div>
  </single-layout>
</template>

<script>
import accountAPI from '@/apis/account';
import { createToast } from '@/helpers'
export default {
  data() {
    return {
      item: {
        firstName: null,
        lastName: null,
        affiliation: null,
        email: null,
        username: null,
        password: null,
        confirmPassword: null,
        tel: null,
        position: null,
        emailOfAffiliation: null,
        telOfAffiliation: null
      },
    };
  },
  mounted() {
    this.$scrollToTop();
  },
  methods: {
    async doRegister() {
      if (!(await this.$refs.observer.validate())){
        return;
      }

      // eslint-disable-next-line no-unused-vars
      const { confirmPassword, ...data } = this.item;
      this.$store.commit("setLoading")
      const result = await accountAPI.createAccount(data);
      this.$store.commit("setLoading")
      if (result) {
        createToast("success", "พวกเราได้รับข้อมูลของคุณเรียบร้อยแล้ว โปรดรอการตอบกลับผ่านทางอีเมลนะครับ")
        this.$router.push({ name: "main" });
      } else {
        createToast("error", "การสมัครล้มเหลว")
      }
    },
    doCancel () {
      this.$router.push({name: 'login'});
    }
  },
};
</script>

<style scoped>
.v-sheet.v-card.v-sheet--shaped {
  border: 4px solid #6b2c8e !important;
  border-radius: 24px 24px !important;
}
</style>
