<template>
  <MainLayout>
    <v-card shaped class="mx-auto ma-4 pa-6">
      <div class="d-flex">      <div><BaseTopic name="ค้นหานวัตกรรม" class="mb-3 mr-2" /></div>
      <div v-if="userData?.role == 'admin'"><v-btn small plain @click="isDownloadDialog = true;"><v-icon color="blank">mdi-download</v-icon>ดาวน์โหลด</v-btn></div></div>
      <v-layout wrap>
        <v-flex sm12 xs12 md3 class="pl-2 pr-2">
          ประเภทการค้นหา
          <v-autocomplete
            v-model="filter.whereBy"
            :items="filterOptions.whereBy"
            item-value="value"
            item-text="text"
            dense
            outlined
            solo
            no-data-text="ไม่พบข้อมูล"
          ></v-autocomplete>
        </v-flex>
        <v-flex sm12 xs12 md3 class="pl-2 pr-2">
          ประเภทการค้นหาย่อย
          <v-autocomplete
            v-model="filter.whereByValue"
            :items="
              filter.whereBy == 'all'
                ? []
                : filterOptions.whereByValue[filter.whereBy]
            "
            dense
            outlined
            solo
            no-data-text="ไม่พบข้อมูล"
            :disabled="filter.whereBy == 'all'"
          ></v-autocomplete>
        </v-flex>
        <v-flex sm12 xs12 md4 class="pl-2 pr-2">
          ระบุคำค้นหา
          <BaseInput
            v-model="filter.keyword"
            solo
            dense
            outlined
            single-line
            clearable
          />
        </v-flex>
        <v-flex sm12 xs12 md2 class="pl-2 pr-2">
          ค้นหา
          <v-btn block color="success" @click="doSearch">
            <v-icon color="white">mdi-check</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        no-data-text="ไม่มีข้อมูล"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        loading-text="กำลังโหลด..."
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-img
            :src="item.image"
            lazy-src="@/assets/rmutdb-logo.png"
            aspect-ratio="1"
            max-width="220"
            max-height="100"
            contain
            class="ma-2"
          >
            <template v-slot:placeholder v-if="item.image">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </template>
        <template v-slot:[`item.nameWithDescription`]="{ item }">
          <b>{{ item.name }}</b
          ><br />
          {{ item.utilization }}
        </template>
        <template v-slot:[`item.action`]="{ item, index }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="userActive && checkPermission(item.id)"
                small
                color="warning"
                @click="openItem('edit', item)"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="primary"
                @click="openItem('read', item)"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>ดู</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="userActive && checkPermission(item.id)"
                small
                color="error"
                @click="openItem('delete', item, index)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ลบ</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <innovation-item-dialog
      v-if="item.dialog"
      v-model="item.dialog"
      :actionType="item.action"
      :id="item.id"
      @save="doHandleSaveItem"
      @close="doClearItem"
    />
    <ConfirmDialog ref="confirm" />

    <v-dialog v-model="isDownloadDialog" width="250">
      <div class="pa-4 white">
             ดาวน์โหลด
      <v-autocomplete
            v-model="download.status"
            :items="status"
            item-value="value"
            item-text="text"
            dense
            outlined
            solo
            no-data-text="ไม่พบข้อมูล"
            class="mb-2 mt-2"
          ></v-autocomplete>
          <div class="text-right"> <ok-cancel-button-group @ok="onDownload" @cancel="isDownloadDialog = false" /> </div>
         
      </div>
    </v-dialog>
  </MainLayout>
</template>

<script>
import * as XLSX from 'xlsx';
import authenticationAPI from "@/apis/authentication";
import innovationAPI from "@/apis/innovation";
import innovationItemDialog from "@/components/InnovationItemDialog";
import ConfirmDialog from "@/components/BaseConfirmDialog.vue";
import { createToast } from "@/helpers";
import OkCancelButtonGroup from '@/components/BaseOkCancelButtonGroup.vue'
export default {
  components: {
    innovationItemDialog,
    ConfirmDialog,
    OkCancelButtonGroup
  },
  data() {
    return {
      headers: [
        {
          text: "รูปภาพประกอบ",
          value: "image",
          class: "primary--text",
        },
        {
          text: "ชื่อนวัตกรรม",
          value: "nameWithDescription",
          class: "primary--text",
          width: "60%",
        },
        {
          text: "รายละเอียด",
          value: "action",
          class: "primary--text",
        },
      ],
      items: [],
      myInnovations: {},
      loading: false,
      item: {
        dialog: false,
        action: "create",
        id: null,
      },
      filter: {
        whereBy: "all",
        whereByValue: null,
        keyword: null,
      },
      filterOptions: {
        whereBy: [
          {
            text: "ทั้งหมด",
            value: "all",
          },
          {
            text: "เจ้าของผลงาน",
            value: "owner",
          },
          {
            text: "กลุ่มเทคโนโลยี",
            value: "techGroup",
          },
        ],
        whereByValue: {
          all: null,
          owner: this.$store.state.affiliationList,
          techGroup: this.$store.state.techGroupList,
        },
      },
      userActive: false,
      isDownloadDialog: false,
      download: {
        status: null
      },
      status: [
        {
          text: 'ทั้งหมด',
          value: 'all'
        },
        {
          text: 'ใช้งาน',
          value: 'available'
        },
        {
          text: 'ไม่ได้ใช้งาน',
          value: 'unavailable'
        }
      ]
    };
  },
  async mounted() {
    this.checkUserActive();
    const { searching } = this.$route.params;
    if (searching) {
      await this.searchHander(searching);
    } else {
      await this.initial();
    }
  },
  methods: {
    async initial() {
      await Promise.all([this.doSearch(), this.getMyInnovations()]);
    },
    async doHandleSaveItem() {
      this.doClearItem();
      await Promise.all([this.doSearch(), this.getMyInnovations()]);
    },
    async getMyInnovations() {
      if (!this.userActive) {
        return;
      }

      this.$store.commit("setLoading");
      const myItems = await innovationAPI.getMyInnovations();
      for (const item of myItems) {
        this.myInnovations[item.id] = item;
      }
      this.$store.commit("setLoading");
    },
    async openItem(action, item, index = null) {
      if (action == "create") {
        this.item.dialog = true;
      } else if (action == "edit") {
        this.item.id = item.id;
        this.item.action = "edit";
        this.item.dialog = true;
      } else if (action == "read") {
        // read
        this.$router.push({
          name: "innovationDetail",
          params: { id: item.id, searching: this.filter },
        });
      } else {
        // delete
        const isConfirmed = await this.$refs.confirm.open(
          "ลบข้อมูล",
          "คุณต้องการลบข้อมูลนี้ใช่หรือไม่"
        );
        if (isConfirmed) {
          const isDeleted = await innovationAPI.deleteInnovation(item.id);
          if (isDeleted) {
            createToast("success", "ลบสำเร็จ");
            this.items.splice(index, 1);
          }
        }
      }
    },
    doClearItem() {
      this.item = {
        dialog: false,
        id: null,
        action: "create",
      };
    },
    async doSearch() {
      const payload = {};
      for (const [key, value] of Object.entries(this.filter)) {
        if (key == "whereBy") {
          payload.whereBy = value;
        }
        if (this.filter.whereBy != "all" && key == "whereByValue" && value) {
          payload.whereByValue = value;
        }
        if (key == "keyword" && value) {
          payload.keyword = value;
        }
      }
      this.$store.commit("setLoading");
      this.items = await innovationAPI.searchInnovations(payload);
      this.$store.commit("setLoading");
    },
    checkPermission(innovationId) {
      if (!this.userActive) {
        return false;
      }

      if (this.userData.role == "admin") {
        return true;
      }

      if (innovationId in this.myInnovations) {
        return true;
      }

      return false;
    },
    async checkUserActive() {
      if (
        (!this.userData && localStorage.getItem("rdtk") == "null") ||
        !localStorage.getItem("rdtk")
      ) {
        this.userActive = false;
      } else {
        this.userActive = true;
        // Get profile
        this.$store.commit("setLoading");
        await authenticationAPI.getProfile();
        this.$store.commit("setLoading");
      }
    },
    async searchHander(searchOption) {
      const { whereBy, whereByValue, keyword } = searchOption;
      if (whereBy) {
        this.filter.whereBy = whereBy;
      }
      if (whereByValue) {
        this.filter.whereByValue = whereByValue;
      }
      if (keyword) {
        this.filter.keyword = keyword;
      }

      await Promise.all([this.doSearch(), this.getMyInnovations()]);
    },
    async onDownload () {
      if (!this.download.status) {
        return;
      }
      this.isDownloadDialog = false;
      this.$store.commit("setLoading");
      const items = await innovationAPI.getInnovations(this.download.status);
      if (items.length == 0) {
        this.$store.commit("setLoading");
        createToast('info', 'ไม่มีข้อมูล')
        return;
      }
      this.exportXLSX(items);
      this.download.status = null;
      this.$store.commit("setLoading");
    },
    exportXLSX(items) {
      const dataWS = XLSX.utils.json_to_sheet(items)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb,'innovations.xlsx')
    },
  },
};
</script>

<style scoped>
.v-sheet.v-card.v-sheet--shaped {
  border: 4px solid #6b2c8e !important;
  border-radius: 24px 24px !important;
}
</style>