import api from './index';
export default {
    async createAccount(model) {
        const { res, err } = await api.axios.post('/account', model);
        return err ? false : res.data.data;
    },
    async getAccounts() {
        const { res, err } = await api.axios.get('/account');
        return err ? {} : res.data.data;
    },
    async updateAccount(id, payload) {
        const { res, err } = await api.axios.patch('/account/' + id, payload);
        return err ? false : res.data.data;
    }
}