import axios from 'axios';
import router from '@/router'
import store from '@/store';
import { createToast } from '@/helpers'

const createErrorAlert = (reason = null) => {
    let message = reason ? reason : "เกิดข้อผิดพลาด พบบางอย่างไม่ถูกต้อง"
    createToast("error", message)
}

const saveToken = (token) => {
    localStorage.removeItem("rdtk");
    localStorage.setItem("rdtk", token);
    axios.defaults.headers.common["Authorization"] = token ? 'Bearer ' + token : '';
}

const saveUser = (userData) => {
    store.commit('setUserData', userData)
}

const doLogout = () => {
    localStorage.removeItem("rdtk");
    delete axios.defaults.headers.common["Authorization"];
    store.commit("setUserData", null);
    router.push({ name: 'login' });
}


axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const userToken = localStorage.getItem('rdtk');
saveToken(userToken);
axios.defaults.headers.common["apiKey"] = process.env.VUE_APP_API_KEY;
axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        createErrorAlert();
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        return { res: response, err: null };
    },
    (error) => {
        if (error.response) {
            let errorMessage = '';
            switch (error.response.status) {
                case 401:
                    errorMessage = 'หมดเวลาการใช้งาน กรุณาเข้าระบบใหมอีกครั้ง';
                    doLogout();
                    break;
                case 400:
                case 404:
                case 500:
                    errorMessage = error.response.data.message;
                    break;
                default:
                    errorMessage = 'ไม่สามารถเชื่อมต่อกับระบบได้';
                    break;
            }
            createErrorAlert(errorMessage);
        }
        return { res: null, err: error };
    }
)

export default {
    axios,
    saveToken,
    saveUser,
    doLogout
}