<template>
  <single-layout>
    <div style="max-width: max-content; margin: 0 auto">
      <v-card
        elevation="6"
        shaped
        width="600px"
        height="max-content"
        class="mb-6 pa-4"
      >
        <BaseTopic name="นโยบายข้อมูลส่วนบุคคล" class="mb-3" />
        การจัดทำห้องสมุดนวัตกรรมและเทคโนโลยีพร้อมใช้นี้มีวัตถุประสงค์เพื่อ
        จัดทำฐานข้อมูล และวิเคราะห์ข้อมูลนวัตกรรม และเทคโนโลยีพร้อมใช้
        เพื่อเผยแพร่สู่สาธารณะ ดังนั้นเมื่อท่านได้ลงทะเบียน
        และได้รับอนุญาตให้สามารถนำเข้าข้อมูลลงในฐานข้อมูลนี้แล้ว
        ข้อมูลที่ท่านนำเข้าต้องเป็นข้อมูลที่ถูกต้อง
        และได้รับอนุญาตจากเจ้าของนวัตกรรมแล้วเท่านั้น
        หากมีข้อร้องเรียนถึงความไม่ถูกต้องของข้อมูล
        หรือการไม่ได้รับอนุญาตให้นำเข้าข้อมูลเกิดขึ้น
        ท่านจะต้องเป็นผู้รับผิดชอบการกระทำดังกล่าว โดยทางผู้ดูแล Website
        จะเป็นผู้รวบรวม เก็บบันทึก และนำเสนอข้อมูลเท่านั้น
        ในการลบหรือทำลายข้อมูล ท่านอาจมีสิทธิขอให้ผู้ดูแล Website
        ดำเนินการลบหรือทำลายข้อมูลที่ท่านนำเข้าได้
        หากข้อมูลนั้นไม่จำเป็นสำหรับวัตถุประสงค์ในการประมวลผลข้อมูลอีกต่อไป
        <div>
          <v-checkbox
            v-model="isAccept"
            :label="`ยอมรับข้อตกลง`"
            @change="acceptionHandler"
          ></v-checkbox>
        </div>
        <div>
          <v-btn block color="success" @click="accepted" :disabled="isButtonDisabled"
            >ยอมรับและดำเนินต่อ</v-btn
          >
        </div>
      </v-card>
    </div>
  </single-layout>
</template>

<script>
export default {
  data() {
    return {
      isAccept: false,
      isButtonDisabled: true
    }
  },
  mounted() {
    this.$scrollToTop();
  },
  methods: {
    accepted() {
      this.$router.push({ name: "register", params: { isAcceptedPDPA: true } });
    },
    acceptionHandler(value) {
      if (value === false) {
        this.isButtonDisabled = true;
        return;
      }

      this.isButtonDisabled = false;
    }
  },
};
</script>

<style scoped>
.v-sheet.v-card.v-sheet--shaped {
  border: 4px solid #6b2c8e !important;
  border-radius: 24px 24px !important;
}
</style>
