<template>
  <main-layout>
    <v-btn small @click="doBack" color="warning" class="pa-4 mr-2">
      <v-icon color="white">mdi-arrow-left-circle</v-icon>
    </v-btn>
    <v-btn small @click="doExportPDF" color="primary" class="pa-4 mr-2">
      Export to PDF
    </v-btn>
    <div style="max-width: max-content; margin: 0 auto; margin-top: 20px">
      <v-card
        elevation="6"
        shaped
        width="700px"
        height="max-content"
        class="mb-6 black--text pa-5"
        color="white"
      >
        <span ref="innodocument">
          <h1 class="text-center mb-3">{{ item.name }}</h1>
          <v-img
            eager
            lazy-src="@/assets/rmutdb-logo.png"
            :src="item.image"
            max-height="300"
            max-width="100%"
            contain
          >
            <template v-slot:placeholder v-if="item.image">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div id="context" class="mt-3">
            <div>
              <div class="header-topic">
                การนำไปใช้ประโยชน์
              </div>
              <div>{{ item.utilization }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">ข้อมูลนวัตกรรม</div>
              <div>{{ item.information }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">จุดเด่น</div>
              <div>{{ item.pros }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">
                เงื่อนไขในการใช้งาน
              </div>
              <div>{{ item.termsOfUse }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">กลุ่มเทคโนโลยี</div>
              <div>{{ item.techGroup }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">
                ระดับเทคโนโลยี (TRL)
              </div>
              <div>{{ item.techLevel }}</div>
            </div>
                        <div class="mt-3">
              <div class="header-topic">
                สถานะการคุ้มครอง
              </div>
              <div>{{ item.protectionStatus }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">
                เลขที่สิทธิบัตร/คำขอ
              </div>
              <div>{{ item.innovationNumber }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">ผู้ประดิษฐ์</div>
              <div>{{ item.inventor }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">
                เจ้าของผลงาน/ต้นสังกัด
              </div>
              <div>{{ item.owner }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">
                เจ้าของผลงาน/ต้นสังกัดร่วม
              </div>
              <div>{{ item.coOwner }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">ที่อยู่ติดต่อ</div>
              <div>{{ item.address }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">โทรศัพท์</div>
              <div>{{ item.tel }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">อีเมล</div>
              <div>{{ item.email }}</div>
            </div>
            <div class="mt-3">
              <div class="header-topic">ชื่อผู้ประสานงาน</div>
              <div>{{ item.coordinator }}</div>
            </div>
                        <div class="mt-3">
              <div class="header-topic">วันที่-เวลา อัพเดตข้อมูล</div>
              <div>{{ item.updatedAt }}</div>
            </div>
          </div>
        </span>
      </v-card>
    </div>
  </main-layout>
</template>

<script>
import html2pdf from 'html2pdf.js'
import innovationAPI from "@/apis/innovation";
export default {
  data() {
    return {
      prevRoute: null,
      item: {
        id: null,
        name: null,
        utilization: null,
        information: null,
        pros: null,
        termsOfUse: null,
        techGroup: null,
        techLevel: null,
        protectionStatus: null,
        innovationNumber: null,
        inventor: null,
        owner: null,
        coOwner: null,
        address: null,
        tel: null,
        coordinator: null,
        image: null,
        updatedAt: null
      },
      prevFilter: null
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async mounted() {
    const { searching } = this.$route.params;
    if (searching) {
      this.prevFilter = searching;
    }
    await this.doGet(this.$route.params.id);
  },
  methods: {
    doBack() {
      if (this.prevRoute && this.prevRoute.name) {
        this.$router.push({ name: this.prevRoute.name, params: { searching: this.prevFilter } });
      } else {
        this.$router.push({ name: "main" });
      }
    },
    async doGet(id) {
      this.$store.commit("setLoading");
      const innovation = await innovationAPI.getInnovation(id);
      if (!innovation) {
        this.$router.push({ name: "main" });
      }

      this.item = innovation;
      this.$store.commit("setLoading");
    },
    doExportPDF() {
      this.$store.commit("setLoading");
      html2pdf(this.$refs.innodocument, {
        margin: 1,
        filename: "innovation.pdf",
        image: { type: "png", quality: 0.98 },
        jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
        html2canvas: {
          dpi: 300,
          letterRendering: true,
          useCORS: true,
        },
      });
      this.$store.commit("setLoading");
    },
  },
};
</script>

<style scoped>
.v-sheet.v-card.v-sheet--shaped {
  border: 4px solid #6b2c8e !important;
  border-radius: 24px 24px !important;
}

.header-topic {
  background: #6B2C8E;
  color: white;
  padding: 5px;
  margin-bottom: 5px;
}
</style>