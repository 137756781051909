import { render, staticRenderFns } from "./BaseTopic.vue?vue&type=template&id=1816e35b&"
import script from "./BaseTopic.vue?vue&type=script&lang=js&"
export * from "./BaseTopic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports