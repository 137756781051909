import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "@/validator/vee-validate";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import MainLayout from "@/layouts/MainLayout.vue";
import SingleLayout from "@/layouts/SingleLayout.vue";
import BaseInput from '@/components/BaseInput.vue'
import BaseTopic from '@/components/BaseTopic.vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import commonMixin from '@/mixins/common'

const options = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 1,
    newestOnTop: true
};
Vue.use(Toast, options);

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('MainLayout', MainLayout);
Vue.component('SingleLayout', SingleLayout);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseTopic', BaseTopic)

Vue.mixin(commonMixin);
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')