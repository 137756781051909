import api from './index';
export default {
    async uploadFile(data) {
        const { res, err } = await api.axios.post('/file', data);
        return err ? null : res.data.data;
    },
    async deleteFile(data) {
        const { res, err } = await api.axios.delete('/file', { data });
        return err ? false : res.data.data;
    }
}