<template>
  <div class="root">
    <div style="position: absolute; top: 0px; left: 0px; right: 0px">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#6b2c8e"
          fill-opacity="1"
          d="M0,64L16,101.3C32,139,64,213,96,250.7C128,288,160,288,192,277.3C224,267,256,245,288,218.7C320,192,352,160,384,149.3C416,139,448,149,480,133.3C512,117,544,75,576,48C608,21,640,11,672,16C704,21,736,43,768,53.3C800,64,832,64,864,53.3C896,43,928,21,960,32C992,43,1024,85,1056,128C1088,171,1120,213,1152,192C1184,171,1216,85,1248,74.7C1280,64,1312,128,1344,160C1376,192,1408,192,1424,192L1440,192L1440,0L1424,0C1408,0,1376,0,1344,0C1312,0,1280,0,1248,0C1216,0,1184,0,1152,0C1120,0,1088,0,1056,0C1024,0,992,0,960,0C928,0,896,0,864,0C832,0,800,0,768,0C736,0,704,0,672,0C640,0,608,0,576,0C544,0,512,0,480,0C448,0,416,0,384,0C352,0,320,0,288,0C256,0,224,0,192,0C160,0,128,0,96,0C64,0,32,0,16,0L0,0Z"
        ></path>
      </svg>
    </div>
    <div class="pl-4 pr-4 pb-5" style="padding-top: 80px">
      <BaseLogo font1Size="60px" font2Size="40px" />

      <div class="text-center mb-7">
        <h2>{{ appName }}</h2>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseLogo from "@/components/BaseLogo";
export default {
  components: {
    BaseLogo,
  },
};
</script>