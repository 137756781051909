<template>
    <main-layout>
        <v-card shaped class="mx-auto ma-4 pa-6">
            <BaseTopic name="จัดการบัญชี" class="mb-3" />
            <v-tabs v-model="tab">
                <v-tab><v-icon color="green" class="pr-2">mdi-check-circle</v-icon>Active&nbsp;<div class="pa-1" style="background: #f5f5f0; color: green; border-radius: 12px;">{{accounts.active.length}}</div></v-tab>
                <v-tab><v-icon color="yellow" class="pr-2">mdi-account-clock</v-icon>Pending&nbsp;<div class="pa-1" style="background: #f5f5f0; color: orange; border-radius: 12px;">{{accounts.pending.length}}</div></v-tab>
                <v-tab><v-icon color="red" class="pr-2">mdi-close-circle</v-icon>Deactive&nbsp;<div class="pa-1" style="background: #f5f5f0; color: red; border-radius: 12px;">{{accounts.deactive.length}}</div></v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <AccountTable :items="accounts.active" @onAction="onAction"></AccountTable>
                </v-tab-item>
                <v-tab-item>
                    <AccountTable :items="accounts.pending" @onAction="onAction"></AccountTable>
                </v-tab-item>
                <v-tab-item>
                    <AccountTable :items="accounts.deactive" @onAction="onAction"></AccountTable>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <ConfirmDialog ref="confirm" />
    </main-layout>
</template>

<script>
import accountAPI from '@/apis/account'
import AccountTable from '@/components/AccountTable.vue'
import { createToast } from '@/helpers';
import ConfirmDialog from "@/components/BaseConfirmDialog.vue";
export default {
    components: {
        AccountTable,
        ConfirmDialog
    },
    data() {
        return {
            tab: null,
            accounts: {
                active: [],
                deactive: [],
                pending: []
            }
        }
    },
    async mounted() {
        await this.getAccounts();
    },
    methods: {
        async getAccounts() {
            const accounts = await accountAPI.getAccounts();
            this.accounts.active = accounts['active'] ?? []
            this.accounts.deactive = accounts['deactive'] ?? []
            this.accounts.pending = accounts['pending'] ?? []
        },
        async onAction(action, item) {
            const messageMap = {
                active: 'เปิดใช้งานบัญชีนี้',
                deactive: 'ระงับการใช้งานบัญชีนี้',
                pending: 'ย้อนสถานะของบัญชีนี้เป็น Pending'
            }
            const isConfirmed = await this.$refs.confirm.open(
                "ดำเนินการ",
                `คุณต้องการ ${messageMap[action]} ใช่หรือไม่`
            );

            if (!isConfirmed) {
                return;
            }

            const isUpdated = await accountAPI.updateAccount(item.id, { status: action })
            if (isUpdated) {
                createToast('success', 'อัพเดตข้อมูลสำเร็จ')
                await this.getAccounts()
            } else {
                createToast('error', 'อัพเดตข้อมูลล้มเหลว')
            }
        }
    }
}
</script>