<template>
  <single-layout>
    <div style="max-width: max-content; margin: 0 auto">
      <v-card
        elevation="6"
        shaped
        width="400px"
        height="max-content"
        class="mb-6 black--text pa-5"
        color="white"
      >
        <div>
          <ValidationObserver ref="observer">
            <div>
              ชื่อผู้ใช้งาน
              <ValidationProvider
                rules="required"
                name="ชื่อผู้ใช้งาน"
                v-slot="{ errors }"
              >
                <BaseInput
                  v-model="username"
                  solo
                  dense
                  outlined
                  single-line
                  :error-messages="errors"
                />
              </ValidationProvider>
            </div>
            <div>
              รหัสผ่าน
              <ValidationProvider
                rules="required"
                name="รหัสผ่าน"
                v-slot="{ errors }"
              >
                <BaseInput
                  v-model="password"
                  solo
                  dense
                  outlined
                  single-line
                  type="password"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </div>
            <div>
              <v-btn
                block
                color="success"
                @click="login"
                :loading="IsButtonLoading"
                class="mb-2"
              >
                เข้าสู่ระบบ
              </v-btn>
            </div>
            <div>
              <v-btn block color="warning" @click="doRegister">
                ลงทะเบียน
              </v-btn>
            </div>
          </ValidationObserver>
        </div>
      </v-card>
    </div>
  </single-layout>
</template>

<script>
import authenticationApi from "@/apis/authentication";
export default {
  data() {
    return {
      username: null,
      password: null,
      IsButtonLoading: false,
    };
  },
  mounted() {
    this.$scrollToTop();
    if (
      localStorage.getItem("rdtk") != "null" &&
      localStorage.getItem("rdtk")
    ) {
      this.$router.push({ name: "main" });
    }
  },
  methods: {
    async login() {
      const isValidated = await this.$refs.observer.validate();
      if (!isValidated) {
        return;
      }
      this.IsButtonLoading = true;
      const status = await authenticationApi.login({
        username: this.username,
        password: this.password,
      });
      if (status) {
        this.$router.push({ name: "main" });
      }
      this.IsButtonLoading = false;
    },
    doRegister() {
      this.$router.push({ name: "acceptPDPA", params: { type: "staff" } });
    },
  },
};
</script>

<style scoped>
.v-sheet.v-card.v-sheet--shaped {
  border: 4px solid #6b2c8e !important;
  border-radius: 24px 24px !important;
}
</style>
