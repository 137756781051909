<template>
  <v-dialog v-model="show" width="600px" persistent>
    <v-card elevation="2" class="pa-5">
      <div class="d-flex justify-space-between">
        <BaseTopic :name="topicName" class="mb-4" />
        <v-btn icon @click="doClose">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </div>
      <div>
        <ValidationObserver ref="observer">
          <div>
            ชื่อนวัตกรรม
            <ValidationProvider
              rules="required|max:300"
              name="ชื่อนวัตกรรม"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.name"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            การนำไปใช้ประโยชน์
            <ValidationProvider
              rules="required|max:6000"
              name="การนำไปใช้ประโยชน์"
              v-slot="{ errors }"
            >
              <v-textarea
                solo
                dense
                outlined
                rows="3"
                v-model="item.utilization"
                :error-messages="errors"
              >
              </v-textarea>
            </ValidationProvider>
          </div>
          <div>
            ข้อมูลนวัตกรรม
            <ValidationProvider
              rules="required|max:6000"
              name="ข้อมูลนวัตกรรม"
              v-slot="{ errors }"
            >
              <v-textarea
                solo
                dense
                outlined
                rows="3"
                v-model="item.information"
                :error-messages="errors"
              >
              </v-textarea>
            </ValidationProvider>
          </div>
          <div>
            จุดเด่น
            <ValidationProvider
              rules="required|max:6000"
              name="จุดเด่น"
              v-slot="{ errors }"
            >
              <v-textarea
                solo
                dense
                outlined
                rows="3"
                v-model="item.pros"
                :error-messages="errors"
              >
              </v-textarea>
            </ValidationProvider>
          </div>
          <div>
            เงื่อนไขการใช้งาน
            <ValidationProvider
              rules="required|max:6000"
              name="เงื่อนไขการใช้งาน"
              v-slot="{ errors }"
            >
              <v-textarea
                solo
                dense
                outlined
                rows="3"
                v-model="item.termsOfUse"
                :error-messages="errors"
              >
              </v-textarea>
            </ValidationProvider>
          </div>
          <div>
            กลุ่มเทคโนโลยี
            <ValidationProvider
              rules="required"
              name="กลุ่มเทคโนโลยี"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="item.techGroup"
                :items="techGroupList"
                dense
                outlined
                solo
                no-data-text="ไม่พบข้อมูล"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </div>
          <div>
            ระดับเทคโนโลยี
            <ValidationProvider
              rules="required"
              name="ระดับเทคโนโลยี"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="item.techLevel"
                :items="techLevelList"
                dense
                outlined
                solo
                no-data-text="ไม่พบข้อมูล"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </div>
          <div>
            สถานะการคุ้มครอง
            <ValidationProvider
              rules="required"
              name="สถานะการคุ้มครอง"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="item.protectionStatus"
                :items="protectionStatusList"
                dense
                outlined
                solo
                no-data-text="ไม่พบข้อมูล"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </div>
          <div>
            เลขที่สิทธิบัตร/อนุสิทธิบัตร/ลิทสิทธิ์/คำขอ
            <ValidationProvider
              rules="required|max:255"
              name="ชื่อ"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.innovationNumber"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            ผู้ประดิษฐ์
            <ValidationProvider
              rules="required|max:255"
              name="ผู้ประดิษฐ์"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.inventor"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            เจ้าของผลงาน/ต้นสังกัด
            <ValidationProvider
              rules="required|max:255"
              name="เจ้าของผลงาน/ต้นสังกัด"
              v-slot="{ errors }"
            >
              <v-combobox
                v-model="item.owner"
                :items="affiliationList"
                dense
                outlined
                solo
                :error-messages="errors"
              ></v-combobox>
            </ValidationProvider>
          </div>
          <div>
            เจ้าของผลงาน/ต้นสังกัดร่วม
            <ValidationProvider
              rules="required|max:255"
              name="เจ้าของผลงาน/ต้นสังกัดร่วม"
              v-slot="{ errors }"
            >
              <v-combobox
                v-model="item.coOwner"
                :items="affiliationList"
                dense
                outlined
                solo
                :error-messages="errors"
              ></v-combobox>
            </ValidationProvider>
          </div>
          <div>
            ที่อยู่ติดต่อ
            <ValidationProvider
              rules="required|max:255"
              name="ที่อยู่ติดต่อ"
              v-slot="{ errors }"
            >
              <v-textarea
                solo
                dense
                outlined
                rows="3"
                v-model="item.address"
                :error-messages="errors"
              >
              </v-textarea>
            </ValidationProvider>
          </div>
          <div>
            เบอร์โทรศัพท์
            <ValidationProvider
              rules="required|max:255"
              name="เบอร์โทรศัพท์"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.tel"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            อีเมล
            <ValidationProvider
              rules="required|email|max:255"
              name="อีเมล"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.email"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            ชื่อผู้ประสานงาน
            <ValidationProvider
              rules="required|max:255"
              name="ชื่อผู้ประสานงาน"
              v-slot="{ errors }"
            >
              <BaseInput
                v-model="item.coordinator"
                solo
                dense
                outlined
                single-line
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div>
            รูปภาพประกอบนวัตกรรม
            <ValidationProvider
              rules="required"
              name="รูปภาพประกอบนวัตกรรม"
              v-slot="{ errors }"
            >
              <base-file-input v-model="item.image" replace>
                <BaseInput
                  v-model="showAttachmentMsg"
                  append-icon="mdi-upload"
                  solo
                  dense
                  outlined
                  single-line
                  readonly
                  :error-messages="errors"
                />
              </base-file-input>
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </div>
      <div class="mt-2 text-right">
        <ok-cancel-button-group @ok="doSave" @cancel="doClose" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import innovationAPI from "@/apis/innovation";
import BaseFileInput from "@/components/BaseFileInput.vue";
import OkCancelButtonGroup from "@/components/BaseOkCancelButtonGroup.vue";
import { createToast } from "@/helpers";
export default {
  props: {
    value: Boolean,
    actionType: { 
      type: String,
      default: "create",
    },
    id: {
      type: String,
    },
  },
  components: {
    OkCancelButtonGroup,
    BaseFileInput
  },
  data() {
    return {
      topicName: null,
      item: {
        id: null,
        name: null,
        utilization: null,
        information: null,
        pros: null,
        termsOfUse: null,
        techGroup: null,
        techLevel: null,
        protectionStatus: null,
        innovationNumber: null,
        inventor: null,
        owner: null,
        coOwner: null,
        address: null,
        tel: null,
        coordinator: null,
        image: null,
      },
      techLevelList: [
        "TRL 1",
        "TRL 2",
        "TRL 3",
        "TRL 4",
        "TRL 5",
        "TRL 6",
        "TRL 7",
        "TRL 8",
        "TRL 9",
      ],
      protectionStatusList: [
        "สิทธิบัตร",
        "สิทธิบัตรการออกแบบ",
        "อนุสิทธิบัตร",
        "ลิขสิทธิ์",
        "ไม่มี",
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    showAttachmentMsg() {
      return this.item.image ? "อัพโหลดรูปภาพเรียบร้อย" : null;
    },
  },
  async mounted() {
    this.topicName = "สร้างนวัตกรรม";
    if (this.actionType == "edit" && this.id) {
      await this.doGet();
      this.topicName = "แก้ไขนวัตกรรม";
    }
  },
  methods: {
    async doClose() {
      this.$emit("close");
    },
    async doSave() {
      if (!(await this.$refs.observer.validate())) {
        return;
      }

      // eslint-disable-next-line no-unused-vars
      const { id, updatedAt, ...data } = this.item;
      if (this.actionType == "create") {
        await this.doCreate(data);
      } else {
        await this.doUpdate(id, data);
      }
    },
    async doCreate(data) {
      this.$store.commit("setLoading");
      const isCreated = await innovationAPI.createInnovation(data);
      this.$store.commit("setLoading");
      if (isCreated) {
        createToast("success", "สร้างนวัตกรรมสำเร็จ");
        this.$emit("save");
      }
    },
    async doUpdate(id, data) {
      this.$store.commit("setLoading");
      const isUpdated = await innovationAPI.updateInnovation(
        id,
        data
      );
      this.$store.commit("setLoading");
      if (isUpdated) {
        createToast("success", "แก้ไขนวัตกรรมสำเร็จ");
        this.$emit("save");
      }
    },
    async doGet() {
      const innovation = await innovationAPI.getInnovation(this.id);
      if (!innovation) {
        this.doClose();
      }

      this.item = innovation;
    },
  },
};
</script>