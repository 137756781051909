import api from './index';
export default {
    async login(model) {
        const { res, err } = await api.axios.post('/login', model);
        if (!err) {
            api.saveToken(res.data.data)
            await this.getProfile();
        }
        return !err;
    },
    async getProfile() {
        const { res, err } = await api.axios.get('/profile');
        if (!err) {
            api.saveUser(res.data.data);
        }
        return res;
    }
}