<template>
   <v-app>
    <v-main class="background-root">
      <router-view/>
      <v-overlay
        :value="IsLoading"
        color="#ffffff"
        opacity="0.8"
        z-index="200001"
      >
        <div>
          <BaseLogo font1Size="50px" font2Size="30px"/>
        </div>
        <div class="mt-4">
          <v-progress-linear
            indeterminate
            color="primary"
            height="10"
            striped
          ></v-progress-linear>
        </div>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import BaseLogo from '@/components/BaseLogo';
import authenticationAPI from '@/apis/authentication';
export default {
  components: {
    BaseLogo
  },
  computed: {
    IsOverlay() {
      return this.$store.state.loading;
    },
  },
  async mounted() {
    if (localStorage.getItem("rdtk") != "null" && localStorage.getItem("rdtk")) {
      await authenticationAPI.getProfile();
    }
  }
};
</script>

<style scoped>
.background-root {
  background: white !important;
}
</style>
