<template>
  <MainLayout>
    <v-card shaped class="mx-auto ma-4 pa-6">
      <div class="d-flex justify-space-between mb-3">
        <BaseTopic name="นวัตกรรมของฉัน" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="success"
              @click="openItem('create')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>เพิ่ม</span>
        </v-tooltip>
      </div>
      <v-data-table
        no-data-text="ไม่มีข้อมูล"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        loading-text="กำลังโหลด..."
      >
        <template v-slot:[`item.no`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <v-img
            :src="item.image"
            lazy-src="@/assets/rmutdb-logo.png"
            aspect-ratio="1"
            max-width="220"
            max-height="100"
            contain
            class="ma-2"
          >
            <template v-slot:placeholder v-if="item.image">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </template>
        <template v-slot:[`item.action`]="{ item, index }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="warning"
                @click="openItem('edit', item)"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="primary"
                @click="openItem('read', item)"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>ดู</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="error"
                @click="openItem('delete', item, index)"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>ดู</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <innovation-item-dialog
      v-if="item.dialog"
      v-model="item.dialog"
      :actionType="item.action"
      :id="item.id"
      @save="doHandleSaveItem"
      @close="doClearItem"
    />
    <ConfirmDialog ref="confirm" />
  </MainLayout>
</template>

<script>
import innovationAPI from "@/apis/innovation";
import innovationItemDialog from "@/components/InnovationItemDialog";
import ConfirmDialog from "@/components/BaseConfirmDialog.vue";
import { createToast } from "@/helpers";
export default {
  components: {
    innovationItemDialog,
    ConfirmDialog,
  },
  data() {
    return {
      headers: [
        {
          text: "ลำดับ",
          value: "no",
          class: "primary--text",
        },
        {
          text: "รูปภาพประกอบ",
          value: "image",
          class: "primary--text",
        },
        {
          text: "ชื่อนวัตกรรม",
          value: "name",
          class: "primary--text",
          width: "50%",
        },
        {
          text: "จัดการ",
          value: "action",
          class: "primary--text",
        },
      ],
      items: [],
      loading: false,
      item: {
        dialog: false,
        action: "create",
        id: null,
      },
    };
  },
  async mounted() {
    await this.getMyInnovations();
  },
  methods: {
    async getMyInnovations() {
      this.$store.commit("setLoading");
      this.items = await innovationAPI.getMyInnovations();
      this.$store.commit("setLoading");
    },
    async openItem(action, item, index = null) {
      if (action == "create") {
        this.item.dialog = true;
      } else if (action == "edit") {
        this.item.id = item.id;
        this.item.action = "edit";
        this.item.dialog = true;
      } else if (action == "read") {
        // read
        this.$router.push({
          name: "innovationDetail",
          params: { id: item.id },
        });
      } else {
        // delete
        const isConfirmed = await this.$refs.confirm.open(
          "ลบข้อมูล",
          "คุณต้องการลบข้อมูลนี้ใช่หรือไม่"
        );
        if (isConfirmed) {
          const isDeleted = await innovationAPI.deleteInnovation(item.id);
          if (isDeleted) {
            createToast("success", "ลบสำเร็จ");
            this.items.splice(index, 1);
          }
        }
      }
    },
    async doHandleSaveItem() {
      this.doClearItem();
      await this.getMyInnovations();
    },
    doClearItem() {
      this.item = {
        dialog: false,
        id: null,
        action: "create",
      };
    },
  },
};
</script>

<style scoped>
.v-sheet.v-card.v-sheet--shaped {
  border: 4px solid #6b2c8e !important;
  border-radius: 24px 24px !important;
}
</style>