import dayjs from "dayjs";
export default {
    computed: {
        IsLoading() {
            return this.$store.state.loading;
        },
        appName() {
            return this.$store.state.appName;
        },
        userData() {
            return this.$store.state.userData;
        },
        dayjs() {
            return dayjs;
        },
        availableEvents() {
            return this.$store.state.events;
        },
        currentSelectedEvent() {
            return this.$store.state.currentEvent;
        },
        affiliationList() {
            return this.$store.state.affiliationList;
        },
        techGroupList() {
            return this.$store.state.techGroupList;
        }
    },
    methods: {
        dateTimeFormatter(timestamp, format = "DD/MM/YYYY HH:mm") {
            return dayjs(timestamp).format(format)
        }
    }
}