<template>
  <div class="text-center red--text font-weight-bold">
    <span :style="{ 'font-size': font1Size }">R</span>
    <span :style="{ 'font-size': font2Size }">M</span>
    <span :style="{ 'font-size': font2Size }">U</span>
    <span :style="{ 'font-size': font2Size }">T</span>
    <span :style="{ 'font-size': font1Size }">D</span>
    <span :style="{ 'font-size': font1Size }">B</span>
  </div>
</template>

<script>
export default {
  props: {
    font1Size: {
      type: String,
      default: "30px",
    },
    font2Size: {
      type: String,
      default: "20px",
    },
  },
};
</script>