<template>
    <MainLayout>
        <v-card shaped class="ma-4 pa-6">
            <BaseTopic name="E-BOOK ห้องสมุดนวัตกรรมและเทคโนโลยีพร้อมใช้ ประจำปี พ.ศ. 2565" class="mb-8" />
            <v-row wrap>
                <v-col v-for="(book, index) in Object.values(ebooks)" :key="index" class="d-flex child-flex" cols="auto"
                    md="3" sm="4">
                    <div class="ebook-cover">
                        <v-img eager lazy-src="@/assets/rmutdb-logo.png" :src="book.cover" max-height="200" contain
                            @click="readEbook(book)">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="primary lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <div class="pa-3 text-center">
                            {{ book.text }}
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <EBookComponent v-if="openEBookDialog" v-model="openEBookDialog" :title="selectedEBook.text"
            :pages="selectedEBook.images" @close="onCloseDialog" />
    </MainLayout>
</template>

<script>
import * as PDFJS from 'pdfjs-dist/build/pdf'
PDFJS.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js';

import EBookComponent from '@/components/EBookComponent.vue';
export default {
    components: {
        EBookComponent
    },
    data() {
        return {
            openEBookDialog: false,
            selectedEBook: null,
            ebooks: {
                'all': {
                    value: 'all',
                    text: 'ห้องสมุดนวัตกรรมและเทคโนโลยีพร้อมใช้',
                    cover: require('@/assets/cover/all.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/all.pdf",
                    images: []
                },
                'agriculture': {
                    value: 'agriculture',
                    text: 'เทคโนโลยีกลุ่มเกษตร',
                    cover: require('@/assets/cover/agriculture.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/agriculture.pdf",
                    images: []
                },
                'transport': {
                    value: 'transport',
                    text: 'เทคโนโลยีกลุ่มขนส่ง',
                    cover: require('@/assets/cover/transport.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/transport.pdf",
                    images: []
                },
                'energy': {
                    value: 'energy',
                    text: 'เทคโนโลยีกลุ่มพลังงาน',
                    cover: require('@/assets/cover/energy.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/energy.pdf",
                    images: []
                },
                'medical': {
                    value: 'medical',
                    text: 'เทคโนโลยีกลุ่มการแพทย์',
                    cover: require('@/assets/cover/medical.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/medical.pdf",
                    images: []
                },
                'social': {
                    value: 'social',
                    text: 'เทคโนโลยีกลุ่มสังคม',
                    cover: require('@/assets/cover/social.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/social.pdf",
                    images: []
                },
                'textile': {
                    value: 'textile',
                    text: 'เทคโนโลยีกลุ่มสิ่งทอ',
                    cover: require('@/assets/cover/textile.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/textile.pdf",
                    images: []
                },
                'environment': {
                    value: 'environment',
                    text: 'เทคโนโลยีกลุ่มสิ่งแวดล้อม',
                    cover: require('@/assets/cover/environment.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/environment.pdf",
                    images: []
                },
                'food': {
                    value: 'food',
                    text: 'เทคโนโลยีกลุ่มอาหาร',
                    cover: require('@/assets/cover/food.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/food.pdf",
                    images: []
                },
                'industrial': {
                    value: 'industrial',
                    text: 'เทคโนโลยีกลุ่มอุตสาหกรรม',
                    cover: require('@/assets/cover/industrial.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/industrial.pdf",
                    images: []
                },
                'other': {
                    value: 'other',
                    text: 'เทคโนโลยีกลุ่มอื่นๆ',
                    cover: require('@/assets/cover/other.png'),
                    file: "https://www.rmutdb.net/resource-stuff/e-book/other.pdf",
                    images: []
                }
            }
        }
    },
    methods: {
        async readEbook(book) {
            this.$store.commit("setLoading");
            if (book.images.length > 0) {
                this.selectedEBook = book;
                this.openEBookDialog = true;
                this.$store.commit("setLoading");
                return;
            }

            const images = await this.convertPDFIntoImages(book.file);
            this.$set(this.ebooks[book.value], 'images', images)
            this.selectedEBook = this.ebooks[book.value];
            this.openEBookDialog = true;
            this.$store.commit("setLoading");
        },
        // eslint-disable-next-line no-unused-vars
        async convertPDFIntoImages(url) {
            const pdf = await PDFJS.getDocument(url).promise;
            return this.getPagesFromPDF(pdf);
        },
        async getPagesFromPDF(pdf) {
            const data = [];
            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                const page = await pdf.getPage(pageNumber);
                const canvas = document.createElement('canvas');

                await this.renderPdfPageToCanvas(page, canvas);

                data.push(canvas.toDataURL('image/png'))
            }

            return data;
        },
        async renderPdfPageToCanvas(page, canvas) {
            const viewport = page.getViewport({ scale: 1.5 });

            // Prepare canvas using PDF page dimensions
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            return page.render({ canvasContext: context, viewport: viewport }).promise;
        },
        onCloseDialog() {
            this.openEBookDialog = false;
            this.selectedEBook = null;
        },
    }
}
</script>


<style scoped>
.v-sheet.v-card.v-sheet--shaped {
    border: 4px solid #6b2c8e !important;
    border-radius: 24px 24px !important;
}

.ebook-cover:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 #6B2C8E, 0 6px 20px 0 #6B2C8E;
}
</style>