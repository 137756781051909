<template>
  <div>
    <div @click="onClickUploadEvent"><slot></slot></div>
    <input
      :disabled="disabled"
      type="file"
      :multiple="multiple"
      class="form-control-file pt-2"
      name="fileUpload"
      ref="fileUpload"
      data-vv-as="File"
      accept="image/*;capture=camera"
      :capture="opencamera"
      @change="onFileChange($event)"
      style="
        overflow: hidden;
        position: fixed;
        width: 1px;
        height: 1px;
        z-index: -1;
        opacity: 0;
      "
    />
  </div>
</template>

<script>
import fileApi from "@/apis/file";
import { createToast } from "@/helpers";
export default {
  props: {
    value: {
      type: String,
      Array,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    opencamera: {
      type: Boolean,
      default: false,
    },
    replace: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileUploaded: [],
    };
  },
  methods: {
    onClickUploadEvent() {
      this.$refs.fileUpload.click();
    },
    async onFileChange(event) {
      if (event.target.files[0] === undefined) {
        return;
      }
      const files = event.target.files;
      const filesArray = [];
      for (const file of files) {
        filesArray.push(file);
      }
      const fileSize = 5000000; //5mb
      const supportTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg"
        // "text/csv",
        // "application/pdf",
        // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const isSupportedType = filesArray.every((file) =>
        supportTypes.includes(file.type)
      );
      const isSupportedSize = filesArray.every((file) => file.size <= fileSize);
      if (!isSupportedType || !isSupportedSize) {
        const errorMessage = !isSupportedType
          ? "ไม่รองรับไฟล์นามสกุลนี้"
          : "ไฟล์มีขนาดใหญ่กว่า 15 MB";
        createToast("error", "ไม่สามารถอัพโหลดได้ เนื่องจาก" + errorMessage);
        return;
      }
      // Only support 1 file
      this.onUpload(filesArray[0]);
    },
    async onUpload(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.$store.commit("setLoading");
      const createdFile = await fileApi.uploadFile(formData);
      this.$store.commit("setLoading");
      if (!createdFile) {
        createToast(
          "error",
          "ไม่สามารถอัพโหลดได้ เนื่องจากเกิดข้อผิดพลาดบางอย่าง"
        );
        return;
      }

      if (this.replace && this.value) {
        this.$store.commit("setLoading");
        await fileApi.deleteFile({ file: this.value });
        this.$store.commit("setLoading");
      }
      // File url
      this.fileUploaded.push(createdFile);
      this.$emit("input", this.fileUploaded[this.fileUploaded.length - 1]);
    },
  },
};
</script>