import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        appName: 'Database Library Innovation',
        userData: null,
        loading: false,
        affiliationList: [
            'มหาวิทยาลัยเทคโนโลยีราชมงคลรัตนโกสินทร์',
            'มหาวิทยาลัยเทคโนโลยีราชมงคลศรีวิชัย',
            'มหาวิทยาลัยเทคโนโลยีราชมงคลอีสาน',
            'มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร',
            'มหาวิทยาลัยเทคโนโลยีราชมงคลกรุงเทพ',
            'มหาวิทยาลัยเทคโนโลยีราชมงคลล้านนา',
            'มหาวิทยาลัยเทคโนโลยีราชมงคลตะวันออก',
            'มหาวิทยาลัยเทคโนโลยีราชมงคลสุวรรณภูมิ',
            'มหาวิทยาลัยเทคโนโลยีราชมงคลธัญบุรี',
        ],
        techGroupList: [
            'เกษตร',
            'ขนส่ง',
            'พลังงาน',
            'แพทย์',
            'สังคม',
            'สิ่งทอ',
            'สิ่งแวดล้อม',
            'อาหาร',
            'อุตสาหกรรม',
            'อื่นๆ'
        ]
    },
    getters: {},
    mutations: {
        setLoading(state) {
            state.loading = !state.loading;
        },
        setUserData(state, payload) {
            state.userData = payload;
        }
    },
    actions: {},
    modules: {}
})