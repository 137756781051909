<template>
  <div class="d-flex">
    <div style="width: 5px; height: 25px" class="primary"></div>
    <div class="ml-3 black--text">{{ name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
};
</script>