<template>
  <div>
    <v-btn small color="success" @click="ok" class="mr-2">
      <v-icon color="white">mdi-check</v-icon>
    </v-btn>
    <v-btn v-if="!disabledCancelBtn" small color="error" @click="cancel">
      <v-icon color="white">mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    disabledCancelBtn: {
      type: Boolean
    }
  },
  methods: {
    ok() {
      this.$emit("ok", true);
    },
    cancel() {
      this.$emit("cancel", true);
    },
  },
};
</script>