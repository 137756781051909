import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import th from 'vee-validate/dist/locale/th';


// loop over all rules
for (let rule in rules) {
    var data = {
        ...rules[rule], // add the rule
        message: th.messages[rule] // add its message
    }

    extend(rule, data);
}

extend('isUrl', {
    message: 'รูปแบบลิ้งไม่ถูกต้อง',
    validate: (value) => {
        let pattern = new RegExp(
                // eslint-disable-next-line no-useless-escape
                /^(ftp|http|https|line):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
            ) // fragment locator
        return pattern.test(value)
    }
})

extend('thaiPhonenumber', {
    message: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง',
    validate: (value) => {
        var pattern = new RegExp(
                /^[0][3-9][0-9]{4}[0-9]{4}$/
            ) // fragment locator
        return pattern.test(value)
    }
})