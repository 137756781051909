import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MainView from '../views/MainView.vue'
import AcceptPDPAView from '../views/AcceptPDPAView.vue'
import RegisterView from '../views/RegisterView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import MineView from '../views/MineView.vue'
import InnovationDetailView from '../views/InnovationDetailView'
import SearchInnovationView from '../views/SearchInnovationView'
import AbouteUsView from '../views/AboutUsView';
import ManageAccountsView from '../views/ManageAccountsView'
import EBookView from '../views/EbookView.vue';

//route middleware
import middlewarePipeline from "./middlewarePipeline";
import auth from "./middleware/auth";
import userActive from './middleware/userActive';
import consent from './middleware/consent';
import admin from './middleware/admin';

import store from '@/store';

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/',
        name: 'main',
        component: MainView
    },
    {
        path: '/acceptPDPA',
        name: 'acceptPDPA',
        component: AcceptPDPAView
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView,
        meta: {
            middleware: [
                userActive,
                consent
            ]
        }
    },
    { path: "*", component: NotFoundView },
    {
        path: '/mine',
        name: 'mine',
        component: MineView,
        meta: {
            middleware: [
                auth,
            ]
        }
    },
    {
        path: '/innovation/:id',
        name: 'innovationDetail',
        component: InnovationDetailView
    },
    {
        path: '/search/innovation',
        name: 'searchInnovation',
        component: SearchInnovationView
    },
    {
        path: '/aboutus',
        name: 'aboutus',
        component: AbouteUsView
    },
    {
        path: '/ebook',
        name: 'ebook',
        component: EBookView
    },
    {
        path: '/manage-accounts',
        name: 'manageAccounts',
        component: ManageAccountsView,
        meta: {
            middleware: [
                auth,
                admin
            ]
        }
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach(async(to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }

    const middleware = to.meta.middleware

    const context = { to, from, next, store }

    const toView = await middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
    return next(toView);
})

export default router