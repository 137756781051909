<template>
  <MainLayout>
    <v-card shaped class="mx-auto ma-4 pa-6">
      <BaseTopic name="ห้องสมุดนวัตกรรมและเทคโนโลยีพร้อมใช้" class="mb-3" />
      <div id="all" class="text-center">
        <h1>
          ข้อมูลทั้งหมด:&nbsp;&nbsp;<span class="primary--text">{{
            item.all
          }}</span>
        </h1>
      </div>
      <v-layout wrap class="mt-8 mb-10">
        <v-flex sm12 xs12 md6 class="pr-3">
          <h2 class="text-center">กลุ่มเทคโนโลยี</h2>
          <Pie
            :chart-options="pieChartOptions"
            :chart-data="chartData"
            chart-id="pieChart"
            :width="400"
            :height="400"
          />
        </v-flex>
        <v-flex sm12 xs12 md6>
          <h2 class="text-center">เจ้าของผลงาน/ต้นสังกัด</h2>
          <Bar
            :chart-options="barChartOptions"
            :chart-data="barData"
            chart-id="barChart"
            :width="400"
            :height="400"
          />
        </v-flex>
      </v-layout>
    </v-card>
  </MainLayout>
</template>

<script>
import innovationAPI from "@/apis/innovation";
import { Pie, Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  BarElement,
  LinearScale
);

export default {
  components: {
    Pie,
    Bar,
  },
  data() {
    return {
      item: {
        all: 0,
        techGroup: [],
        ownerGroup: [],
      },
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, array) => {
          this.pieChartEventHandler(event, array)
        }
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
      },
      chartData: {
        labels: [],
        datasets: [],
      },
      barData: {
        labels: [],
        datasets: [],
      },
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.$store.commit("setLoading");
      this.item = await innovationAPI.getInnovationDashboard();

      // Serialize data
      const techColorMap = {
        'สิ่งแวดล้อม': '#00ff00',
        'อุตสาหกรรม': '#9900ff',
        'พลังงาน': '#cc0000',
        'อาหาร': '#ffff00',
        'แพทย์': '#00ffff',
        'สิ่งทอ': '#ff00ff',
        'อื่นๆ': '#b7b7b7',
        'เกษตร': '#34a853',
        'สังคม': '#ff9900',
        'ขนส่ง': '#0000ff'
      }
      const techGroupText = this.item.techGroup.map((tg) => tg.techGroup);
      const techGroupCount = this.item.techGroup.map((tg) => tg.count);
      const techGroupColor = this.item.techGroup.map(tg => techColorMap[tg.techGroup]);
      this.chartData = {
        labels: techGroupText,
        datasets: [
          {
            label: "กลุ่มเทคโนโลยี",
            backgroundColor: techGroupColor,
            data: techGroupCount,
          },
        ],
      };

      const ownerColorMap = {
        'มหาวิทยาลัยเทคโนโลยีราชมงคลศรีวิชัย': '#ffff00',
        'มหาวิทยาลัยเทคโนโลยีราชมงคลรัตนโกสินทร์': '#cc0000',
         'มหาวิทยาลัยเทคโนโลยีราชมงคลล้านนา': '#d2691e',
         'มหาวิทยาลัยเทคโนโลยีราชมงคลกรุงเทพ': '#33ff00',
         'มหาวิทยาลัยเทคโนโลยีราชมงคลธัญบุรี': '#0000ff',
         'มหาวิทยาลัยเทคโนโลยีราชมงคลอีสาน': '#ff5c00',
         'มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร': '#bf00ff',
         'มหาวิทยาลัยเทคโนโลยีราชมงคลตะวันออก': '#1874cd',
         'มหาวิทยาลัยเทคโนโลยีราชมงคลสุวรรณภูมิ': '#eec900'
      }
      const ownerGroupText = this.item.ownerGroup.map((tg) => tg.owner);
      const ownerGroupCount = this.item.ownerGroup.map((tg) => tg.count);
      const ownerGroupColor = this.item.ownerGroup.map(ow => ownerColorMap[ow.owner]);
      this.barData = {
        labels: ownerGroupText,
        datasets: [
          {
            fill: false,
            axis: "y",
            label: "เจ้าของผลงาน/ต้นสังกัด",
            backgroundColor: ownerGroupColor,
            data: ownerGroupCount,
          },
        ],
      };
      this.$store.commit("setLoading");
    },
    genColor() {
      // const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
      const randomNum = () => Math.floor(Math.random() * 255);
      return `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
    },
    pieChartEventHandler (event, array) {
     if (array.length > 0) {
      const label = this.chartData.labels[array[0].index]
      this.$router.push({ name: 'searchInnovation', params: { searching: { whereBy: 'techGroup', whereByValue: label } }})
     }
    }
  },
};
</script>

<style scoped>
.v-sheet.v-card.v-sheet--shaped {
  border: 4px solid #6b2c8e !important;
  border-radius: 24px 24px !important;
}
</style>